import http from './interface'
import { UserModule } from '@/store/modules/user'
import { IWineryListQuery, IWinerySortReqModel, IWineryReqModel } from '@/pages/winery/data/wineryModel'
import {  IWineryAfterNoonTeaModel } from '@/pages/winery-afternoon-tea/data/wineryModel'
import qs from 'qs';

export const getWineryCountByStatus = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/introductions/get/count`)
}

export const getWineryAfternoonTeaCountByStatus = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea/count-stats`)
}

export const getWineryRecords = (data: IWineryListQuery) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/introductions?${qs.stringify(data)}`
  return http.get(url)
}

// 取消发布和发布
export const updateWineryPublishStatus = (
  wineryId: string,
  isPublished: boolean,
  isUsed: boolean
) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/introductions/${wineryId}`,
    {
      isPublished,
      isUsed
    }
  )
}

// 删除
export const deleteWinery = (wineryId: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/introductions/${wineryId}`)
}

// 详情
export const getWineryDetails = (wineryId: string) => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/introductions/${wineryId}`)
}

// 新增
export const addWinery = (data: IWineryReqModel) => {
  const { introductionMedia, ...rest } = data;
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/introductions`, {
    ...rest,
    // homePageMediaId: homePageMedia[0].id,
    introductionMediaId: introductionMedia[0].id
  })
}

// 修改
export const updateWinery = (wineryId: string, data: IWineryReqModel) => {
  const { introductionMedia, ...rest } = data;
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/introductions/introduction/${wineryId}`, {
    ...rest,
    // homePageMediaId: homePageMedia[0].id,
    introductionMediaId: introductionMedia[0].id
  })
}

// 更新winery展示顺序
export const updateWineryListSort = (list: IWinerySortReqModel[]) => {
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/introductions/sort`, list)
}

// 更新winery展示顺序
export const updateWineryAfterNoonTeaListSort = (productIds: String[]) => {
  return http.patch(`/api/v3/wineries/${UserModule.wineryId}/products/sort`, {productIds})
}

// 下午茶列表
export const getWineryAfterNoonTea = (data: IWineryListQuery) => {
  if(data.status==='all'){
    return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea?${qs.stringify(data)}`)
  }else if(data.status==='published'){
    return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea?isPublished=true`)
  }else{
    return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea?isPublished=false`)
  }
  
}
// 下午茶列表
export const getWineryAfterNoonTea2 = (isPublished:boolean) => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea?isPublished=${isPublished}`)
}

// 添加下午茶
export const addWineryAfterNoonTea = (data: IWineryAfterNoonTeaModel) => {
  const { details, ...rest } = data;
   
  const { coverImageUrl,detailCoverImageUrls,detailContentImageUrls, ...rest2 } = details;
  const arr:any=[]
  detailContentImageUrls.forEach((element:any)=>{
    if(element.path.startsWith("http")){ 
      arr.push(element.path)
    }else{ 
      arr.push(process.env.VUE_APP_BASE_API+"/"+element.path)
    }
  })

  const arr2:any=[]
  detailCoverImageUrls.forEach((element:any)=>{
    if(element.path.startsWith("http")){ 
      arr2.push(element.path)
    }else{ 
      arr2.push(process.env.VUE_APP_BASE_API+"/"+element.path)
    }
  })
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea`, {
    ...rest,
    details:{
      ...rest2,
      coverImageUrl:process.env.VUE_APP_BASE_API+"/"+coverImageUrl[0].path,
      detailCoverImageUrls:arr2,
      detailContentImageUrls:arr,
    }
  })
}

// 修改
export const updateWineryAfterNoonTea = (productId: string, data: IWineryAfterNoonTeaModel) => {
  
  const { details, ...rest } = data;
  const { coverImageUrl,detailCoverImageUrls,detailContentImageUrls, ...rest2 } = details;
  let newCoverImageUrl,newDetailCoverImageUrl
  let newDetailContentImageUrls:any=[]
  let newdetailCoverImageUrl:any=[]
  if(coverImageUrl[0].path.startsWith("http")){
    newCoverImageUrl=coverImageUrl[0].path
  }else{
    newCoverImageUrl=process.env.VUE_APP_BASE_API+"/"+coverImageUrl[0].path
  }
  detailCoverImageUrls.forEach((element:any)=>{
    if(element.path.startsWith("http")){
      newdetailCoverImageUrl.push(element.path)
    }else{
      newdetailCoverImageUrl.push(process.env.VUE_APP_BASE_API+"/"+element.path)
    }
  })
  detailContentImageUrls.forEach((element:any)=>{
    if(element.path.startsWith("http")){
      newDetailContentImageUrls.push(element.path)
    }else{
      newDetailContentImageUrls.push(process.env.VUE_APP_BASE_API+"/"+element.path)
    }
  })
  
  
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea/${productId}`, {
    ...rest,
    details:{
      ...rest2,
      coverImageUrl:newCoverImageUrl,
      detailCoverImageUrls:newdetailCoverImageUrl,
      detailContentImageUrls:newDetailContentImageUrls,
    }
  })
}

// 删除
export const deleteWineryAfterNoonTea = (productId: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/products/${productId}`)
}

// 取消发布和发布
export const updateWineryAfterNoonTeaPublishStatus = (
  wineryId: string,
  productId: string,
  isPublished: boolean,
) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/products/afternoon-tea/${productId}`,
    {
      isPublished 
    }
  )
}